import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRecoveryQuery } from "./useRecoveryQuery";
import { EmailDTO, RecoveryDTO } from "../../../services/AuthService";

type Props = {
  searchParams: URLSearchParams;
};

const useRecoveryForm = ({ searchParams }: Props) => {
  const defaultValues = {
    email: "",
  };

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .email("Format incorrect")
        .required("Email obligatoire"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const recoveryQuery = useRecoveryQuery();

  const onSubmit = (values: EmailDTO) => {
    const paramFrom = searchParams.get("from");
    const recoveryDTO: RecoveryDTO = { ...values, from: paramFrom };
    recoveryQuery.mutate(recoveryDTO);
  };

  const isLoading = recoveryQuery.isLoading;

  const isSuccess = recoveryQuery.isSuccess;

  const submit = handleSubmit(onSubmit);

  return {
    errors,
    submit,
    register,
    isLoading,
    isSuccess,
  };
};

export { useRecoveryForm };
