import { Route, Routes } from "react-router-dom";
import { Auth } from "../views/Auth/Auth";
import { Password } from "../views/Password/Password";
import { Recovery } from "../views/Recovery/Recovery";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Auth />}></Route>

      <Route path="/recovery" element={<Recovery />} />

      <Route path="/password" element={<Password />} />
    </Routes>
  );
}

export default Router;
