import AccountBoxIcon from "@mui/icons-material/AccountBox";

import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  CardContent,
} from "@mui/material";

import logo_m2M from "../../assets/M2M_Logo-blanc.png";
import { useRecovery } from "./hooks/useRecovery";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LoadingButton } from "../../components/buttons/LoadingButton";
import { CustomAlert } from "../../components/alerts/Alert";

const Recovery = () => {
  const { recoveryForm, searchParams } = useRecovery();
  const navigate = useNavigate();

  let content = generateContent(searchParams, recoveryForm);
  let actions = generationActions(recoveryForm, navigate, searchParams);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item>
        <CardContent>
          <form onSubmit={recoveryForm.submit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <img src={logo_m2M} width="150px" alt="logo m2m" />
              </Grid>
              <Grid item>{content}</Grid>
              <Grid item>
                <Grid container justifyContent="center" spacing={2}>
                  {actions}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Grid>
    </Grid>
  );
};

function generationActions(
  recoveryForm: any,
  navigate: NavigateFunction,
  searchParams: URLSearchParams
) {
  if (!recoveryForm.isSuccess) {
    return (
      <>
        <Grid item>
          <Button
            onClick={() => navigate(-1)}
            color="inherit"
            variant="outlined"
          >
            Retour
          </Button>
        </Grid>
        {searchParams.get("from") && (
          <Grid item>
            <LoadingButton
              loading={recoveryForm.isLoading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Valider
            </LoadingButton>
          </Grid>
        )}
      </>
    );
  } else {
    return <></>;
  }
}

function generateContent(searchParams: URLSearchParams, recoveryForm: any) {
  if (!searchParams.get("from")) {
    return (
      <CustomAlert variant="outlined" severity="error">
        Une erreur est survenue
      </CustomAlert>
    );
  } else if (recoveryForm.isSuccess) {
    return (
      <CustomAlert variant="outlined" severity="success">
        Mail de réinitialisation du mot de passe envoyé.
        <br />
        Vous pouvez fermer cette page.
      </CustomAlert>
    );
  } else {
    return (
      <TextField
        {...recoveryForm.register("email")}
        label="Email de récupération"
        sx={{ m: 1 }}
        helperText={recoveryForm.errors.email?.message}
        error={recoveryForm.errors.email ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <AccountBoxIcon></AccountBoxIcon>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export { Recovery };
