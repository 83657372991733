import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/AuthService";

const useLoginQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.signin, {
    onSuccess: () => {
      enqueueSnackbar("Authentification réussie", { variant: "success" });
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
};

export { useLoginQuery };
