import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockIcon from "@mui/icons-material/Lock";

import {
  Grid,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  CardContent,
  Typography,
} from "@mui/material";
import { useAuth } from "./hooks/useAuth";

import logo_m2M from "../../assets/M2M_Logo-blanc.png";
import { Link } from "react-router-dom";
import { LoadingButton } from "../../components/buttons/LoadingButton";
import { CustomAlert } from "../../components/alerts/Alert";

const Auth = () => {
  const { authForm, isPasswordShown, toggleShowPassword, searchParams } =
    useAuth();

  let content = generateContent(
    searchParams,
    authForm,
    isPasswordShown,
    toggleShowPassword
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item>
        <CardContent>
          <form onSubmit={authForm.submit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <img src={logo_m2M} width="150px" alt="logo m2m" />
              </Grid>
              {content}
            </Grid>
          </form>
        </CardContent>
      </Grid>
    </Grid>
  );
};

function generateContent(
  searchParams: URLSearchParams,
  authForm: any,
  isPasswordShown: boolean,
  toggleShowPassword: () => void
) {
  if (!searchParams.get("from")) {
    return (
      <>
        <Grid item>
          <CustomAlert variant="outlined" severity="error">
            Une erreur est survenue
          </CustomAlert>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item>
          <TextField
            {...authForm.register("email")}
            label="Email"
            sx={{ m: 1 }}
            helperText={authForm.errors.email?.message}
            error={authForm.errors.email ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AccountBoxIcon></AccountBoxIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            {...authForm.register("password")}
            label="Mot de passe"
            sx={{ m: 1 }}
            helperText={authForm.errors.password?.message}
            error={authForm.errors.password ? true : false}
            type={isPasswordShown ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox />}
            label={<Typography>Afficher le mot de passe</Typography>}
            onChange={toggleShowPassword}
            color="white"
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={authForm.isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Connexion
          </LoadingButton>
        </Grid>
        <Grid item>
          <Typography
            color={"secondary"}
            component={Link}
            to={`/recovery?from=${searchParams.get("from")}`}
          >
            Mot de passe oublié ?
          </Typography>
        </Grid>
      </>
    );
  }
}

export { Auth };
