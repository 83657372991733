import { Alert, AlertProps, Typography } from "@mui/material";

const CustomAlert = (props: AlertProps) => {
  const { children, ...rest } = props;
  return (
    <Alert {...rest}>
      <Typography>{children}</Typography>
    </Alert>
  );
};

export { CustomAlert };
