import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginQuery } from "./useAuthQuery";
import { Credentials, SigninDTO } from "../../../services/AuthService";

type Props = {
  searchParams: URLSearchParams;
};

const useAuthForm = ({ searchParams }: Props) => {
  const defaultValues = {
    email: "",
    password: "",
  };

  const schema = yup
    .object()
    .shape({
      email: yup
        .string()
        .email("Format incorrect")
        .required("Email obligatoire"),
      password: yup.string().required("Mot de passe obligatoire"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const authLoginQuery = useLoginQuery();

  const onSubmit = (values: Credentials) => {
    const paramFrom = searchParams.get("from");
    const signinDTO: SigninDTO = { ...values, from: paramFrom };

    authLoginQuery.mutate(signinDTO);
  };

  const submit = handleSubmit(onSubmit);

  const isLoading = authLoginQuery.isLoading;

  return {
    errors,
    submit,
    register,
    isLoading,
  };
};

export { useAuthForm };
