import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../services/AuthService";

interface Props {
  paramFrom: string | null;
}

const usePasswordQuery = ({ paramFrom }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return useMutation(AuthService.setPassword, {
    onSuccess: () => {
      enqueueSnackbar("Mot de passe mis à jour", { variant: "success" });
      navigate(`/?from=${paramFrom}`);
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
};
export { usePasswordQuery };
