import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthForm } from "./useAuthForm";
import { USER_NOT_AUTHORIZED_QUERY_STRING } from "../../constants";
import { useSnackbar } from "notistack";

const useAuth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const toggleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  let [searchParams] = useSearchParams();
  const authForm = useAuthForm({ searchParams });

  useEffect(() => {
    const paramUserNotAuthorized = searchParams.get(
      USER_NOT_AUTHORIZED_QUERY_STRING
    );

    if (paramUserNotAuthorized !== null)
      enqueueSnackbar("Accès non autorisé", { variant: "error" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authForm,
    isPasswordShown,
    toggleShowPassword,
    searchParams,
  };
};
export { useAuth };
