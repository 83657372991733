import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePasswordForm } from "./usePasswordForm";

const usePassword = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const toggleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  let [searchParams] = useSearchParams();

  const passwordForm = usePasswordForm({ searchParams });

  return {
    isPasswordShown,
    toggleShowPassword,
    passwordForm,
    searchParams,
  };
};
export { usePassword };
