import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { usePasswordQuery } from "./usePasswordQuery";
import {
  ConfirmPasswordDTO,
  SetPasswordDTO,
} from "../../../services/AuthService";

type Props = {
  searchParams: URLSearchParams;
};

const usePasswordForm = ({ searchParams }: Props) => {
  const defaultValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const schema = yup
    .object()
    .shape({
      newPassword: yup
        .string()
        .required("Mot de passe obligatoire")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/,
          "12 caractères minimum dont au moins 1 majuscule, 1 miniscule, 1 chiffre et 1 caractère spécial"
        ),
      confirmPassword: yup
        .string()
        .required("Confirmation obligatoire")
        .oneOf([yup.ref("newPassword")], "Mot de passe invalide"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const paramFrom = searchParams.get("from");

  const passwordQuery = usePasswordQuery({ paramFrom });

  const onSubmit = (values: ConfirmPasswordDTO) => {
    const paramId = searchParams.get("id");
    const paramToken = searchParams.get("token");
    const setPasswordDTO: SetPasswordDTO = {
      id: paramId,
      password_token: paramToken,
      newPassword: values.newPassword,
      from: paramFrom,
    };

    passwordQuery.mutate(setPasswordDTO);
  };

  const submit = handleSubmit(onSubmit);

  const isLoading = passwordQuery.isLoading;

  return {
    errors,
    submit,
    register,
    isLoading,
  };
};

export { usePasswordForm };
