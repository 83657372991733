import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, children, ...rest } = props;
  return (
    <Button {...rest} disabled={loading || rest.disabled}>
      {loading ? (
        <>
          &nbsp;
          <CircularProgress color="inherit" size={16} />
          &nbsp;
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export { LoadingButton };
