import api from "./http-api";

export interface EmailDTO {
  email: string;
}

export interface PasswordDTO {
  password: string;
}

export interface ConfirmPasswordDTO {
  newPassword: string;
  confirmPassword?: string;
}

export interface FromDTO {
  from: string | null;
}

export interface IdAndPasswordTokenDTO {
  id: string | null;
  password_token: string | null;
}

export type Credentials = EmailDTO & PasswordDTO;

export type SigninDTO = Credentials & FromDTO;

export type RecoveryDTO = EmailDTO & FromDTO;

export type SetPasswordDTO = IdAndPasswordTokenDTO &
  ConfirmPasswordDTO &
  FromDTO;

const AuthService = {
  signin: (signinDTO: SigninDTO): Promise<void> =>
    api.post("/auth", {
      email: signinDTO.email,
      password: signinDTO.password,
      from: signinDTO.from,
    }),
  recovery: (recoveryDTO: RecoveryDTO): Promise<void> =>
    api.post("/auth/password/recovery", {
      email: recoveryDTO.email,
      from: recoveryDTO.from,
    }),
  setPassword: (setPasswordDTO: SetPasswordDTO): Promise<void> =>
    api.post("/auth/password", {
      id: setPasswordDTO.id,
      password_token: setPasswordDTO.password_token,
      password: setPasswordDTO.newPassword,
      from: setPasswordDTO.from,
    }),
};

export { AuthService };
