import { useSearchParams } from "react-router-dom";
import { useRecoveryForm } from "./useRecoveryForm";

const useRecovery = () => {
  let [searchParams] = useSearchParams();
  const recoveryForm = useRecoveryForm({ searchParams });

  return {
    recoveryForm,
    searchParams,
  };
};
export { useRecovery };
