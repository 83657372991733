import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { AuthService } from "../../../services/AuthService";

const useRecoveryQuery = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(AuthService.recovery, {
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
};
export { useRecoveryQuery };
