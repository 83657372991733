import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CardContent,
  Typography,
} from "@mui/material";

import logo_m2M from "../../assets/M2M_Logo-blanc.png";
import { CustomAlert } from "../../components/alerts/Alert";
import { usePassword } from "./hooks/usePassword";

const Password = () => {
  const { searchParams, passwordForm, isPasswordShown, toggleShowPassword } =
    usePassword();

  let content = generateContent(
    searchParams,
    passwordForm,
    isPasswordShown,
    toggleShowPassword
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Grid item>
        <CardContent>
          <form onSubmit={passwordForm.submit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <img src={logo_m2M} width="150px" alt="logo m2m" />
              </Grid>
              {content}
            </Grid>
          </form>
        </CardContent>
      </Grid>
    </Grid>
  );
};

function generateContent(
  searchParams: URLSearchParams,
  passwordForm: any,
  isPasswordShown: boolean,
  toggleShowPassword: () => void
) {
  if (
    !searchParams.get("token") ||
    !searchParams.get("id") ||
    !searchParams.get("from")
  ) {
    return (
      <Grid item>
        <CustomAlert variant="outlined" severity="error">
          Une erreur est survenue
        </CustomAlert>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid item>
          <TextField
            {...passwordForm.register("newPassword")}
            label="Nouveau mot de passe"
            sx={{ m: 1, width: 200 }}
            helperText={passwordForm.errors.newPassword?.message}
            error={passwordForm.errors.newPassword ? true : false}
            type={isPasswordShown ? "text" : "password"}
          />
        </Grid>
        <Grid item>
          <TextField
            {...passwordForm.register("confirmPassword")}
            label="Confirmation"
            sx={{ m: 1, width: 200 }}
            helperText={passwordForm.errors.confirmPassword?.message}
            error={passwordForm.errors.confirmPassword ? true : false}
            type={isPasswordShown ? "text" : "password"}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox />}
            label={<Typography>Afficher le mot de passe</Typography>}
            onChange={toggleShowPassword}
            color="white"
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Valider
          </Button>
        </Grid>
      </>
    );
  }
}

export { Password };
