import { createTheme, Paper, ThemeProvider } from "@mui/material";
import Routes from "./navigation/Routes";
import M2M from "./assets/background.png";

function App() {
  const muiTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#636870",
      },
      secondary: {
        main: "#d44ccd",
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <Paper
        style={{
          backgroundImage: `url(${M2M})`,
          backgroundPosition: "center",
          backgroundSize: "100%",
        }}
      >
        <Routes />
      </Paper>
    </ThemeProvider>
  );
}

export default App;
